import { render, staticRenderFns } from "./Reservation.vue?vue&type=template&id=545313fb&scoped=true&"
import script from "./Reservation.vue?vue&type=script&lang=js&"
export * from "./Reservation.vue?vue&type=script&lang=js&"
import style0 from "./Reservation.vue?vue&type=style&index=0&id=545313fb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545313fb",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vlaams-brabant/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BTable, BCard, BCol, BButton, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BTable, BCard, BCol, BButton, BRow, BContainer})
    

export default component.exports