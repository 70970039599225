<template>
  <b-card>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="2">
        <p>
          <strong>Status</strong>
          <br />
          {{ reservationStatus }}
        </p>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="2">
        <p>
          <strong>Afhaallocatie</strong>
          <br />
          {{ reservation.WarehouseID.replace('MINI ', '') }}
        </p>
      </b-col>
      <b-col>
        <p>
          <strong>Afhaaldatum</strong>
          <br />
          {{ expectedStart }}
          om {{ reservation.BusinessHourStart }}
        </p>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="4">
        <p>
          <strong>Terugbrengdatum</strong>
          <br />
          {{ expectedEnd }}
          om {{ reservation.BusinessHourEnd }}
        </p>
      </b-col>
      <!--<b-col>
          <router-link :to="{ name: 'reservation' }"
            >Download factuur</router-link
          >
        </b-col>-->
    </b-row>
  </b-card>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    reservation: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    reservationStatus: function() {
      let status = this.reservation.Status
      if (this.reservation.WarehouseID.includes('MINI') && status !== 'Closed')
        return 'Schade'
      if (this.reservation.PayMethodID === 'PROBLEEMRESERVERING')
        return 'Schade'
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Gereserveerd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return 'Aangevraagd'
    },
    expectedStart() {
      return dayjs(this.reservation.DateTimeExpectedStart).format('DD/MM/YYYY')
    },
    expectedEnd() {
      return dayjs(this.reservation.DateTimeExpectedEnd).format('DD/MM/YYYY')
    }
  }
}
</script>

<style></style>
