import { render, staticRenderFns } from "./ReservationHeader.vue?vue&type=template&id=5afa7422&"
import script from "./ReservationHeader.vue?vue&type=script&lang=js&"
export * from "./ReservationHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vlaams-brabant/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BCard} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BCard})
    

export default component.exports