<template>
  <b-card>
    <b-row>
      <b-col md="6" sm="12">
        <div>
          <p>
            <strong>Omschrijving evenement</strong>
            <br />
            {{ reservation.CUST_EventDescription || 'N.v.t.' }}
          </p>
        </div>
        <div>
          <p>
            <strong>Startdatum en -uur evenement</strong>
            <br />
            {{ eventDate }}
            <span
              v-if="
                reservation.CUST_EventDate && reservation.CUST_EventTimePeriod
              "
              >om</span
            >
            {{ reservation.CUST_EventTimePeriod }}
          </p>
        </div>
        <div>
          <p>
            <strong>Adres waar het evenement doorgaat</strong>
            <br />
            {{ reservation.CUST_EventAddress || 'N.v.t.' }}
          </p>
        </div>
      </b-col>
      <b-col md="6" sm="12">
        <div>
          <p>
            <strong>Verwachte opkomst (aantal personen)</strong>
            <br />
            {{
              formatNumber(reservation.CUST_EventExpectedParticipants) ||
                'N.v.t.'
            }}
          </p>
        </div>
        <div>
          <p>
            <strong>Opmerkingen, bestelbonnummer of referentie</strong>
            <br />
            {{ reservation.CUST_Notes || 'N.v.t.' }}
          </p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    reservation: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    eventDate() {
      return dayjs(this.reservation.CUST_EventDate).format('DD/MM/YYYY')
    }
  },
  methods: {
    formatNumber: num => {
      if (!num) return
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
  }
}
</script>
